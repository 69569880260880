.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f9eebb;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  border: 0.8px solid #585858;
  div {
    display: flex;
    .customerTotals{
      text-align: center;
      color: #585858;
      background-color: #fff;
      padding: 0 5px;
      border-right: 0.8px solid #585858;
    }
    .total{
      color: #444;
      padding-left:50px;
    }
    .graphic{
      position: absolute;
      right: 0;
      padding: 0 20px;
      background-color: #ebc317;
      color: #000;
    }
  }
}
