$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

//Colors
$black: #000;
$white: #fff;

$main: #feb719;

$light-gray: #f7f7f7;
$gray: #eee;
$mid-gray: #d6d6d6; //#e1e1e1;
$dark-gray: #999;
$extra-dark-gray: #666;

$danger: #e91e63;
$warning: #feb719;
$success: #8bc34a;
$info: #00bcd4;
$data: #e2e3e5;

$dark-green: #00692c;
$dark-red: #990000;

$white-transparent: rgba(255, 255, 255, 0.3);
$black-transparent: rgba($black, 0.5);
$yellow-transparent: rgba($main, 0.5);

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Breakout container width
$breakout-container-max-width: 1900px !default;

//Base Height of element
$h-45: 45px;

//Base Padding
$p-2: 2px;
$p-3: 3px;
$p-5: 5px;
$p-10: 10px;
$p-15: 15px;
$p-20: 20px;
$p-25: 25px;
$p-30: 30px;

//Margins
$m-5: 5px;
$m-10: 10px;
$m-15: 15px;
$m-20: 20px;
$m-25: 25px;
$m-30: 30px;

//Font
$main-font: "Roboto", sans-serif;
$icon-font: "Font Awesome\ 5 Free";

$f-thin: 100;
$f-light: 300;
$f-regular: 400;
$f-medium: 500;
$f-bold: 700;
$f-black: 900;

$f-10: 10px;
$f-12: 12px;
$f-14: 14px;
