.wrapper {
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
  overflow-x: hidden;
  .tableWrapper{
    display:grid;
    grid-template-columns: 1fr 5fr;
    .filterMenu{
      background-color: #eee;
      height: 97vh;
    }
  }
  header {
    display: flex;
    .username{
      position: absolute;
      right: 30px;
    }
    .fullName{
      padding: 0 10px;
    }
    .countryInfo {
      display: inline-block;
      img {
        margin: 0 10px 0 0;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.48);
        width: 20px;
        position: relative;
        top: -1px;
      }
    }
  }
}
