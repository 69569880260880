.usernameInputWrapper {
	input,
	button {
		display: inline-block;
	}
	input {
		width: unset;
	}
	div {
		margin: 10px 0;
		width: 400px;
	}
}

.textarea {
	height: 125px !important;
}
