.radioInput {
  padding: 0 10px 0 20px;

  &:last-child {
    margin-right: 0;
  }

  input:checked ~ label:before {
    background-color: #ebc317;
  }

  input {
    display: none;
  }

  label {
    color: rgba(0, 0, 15, 0.6) !important;
    font-size: 12px !important;
    font-family: Arial, serif !important;
    font-weight: normal !important;
    text-transform: none !important;
    position: relative;
    cursor: pointer;
    min-width: 0;
    padding: 0;
    margin: 0;
    line-height: 2.08;

    &:hover:before {
      background-color: lighten(#ebc317, 30%);
    }

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      padding: 3px;
      left: -20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 6px;
      height: 6px;
      border: 1px solid #707070;
      border-radius: 50%;
      background-clip: content-box;
      box-sizing: content-box;
      background-color: white;
    }
  }
}
