.layout {
	display: grid;
	grid-template-columns:350px 350px;
	p {
		margin: 0;
	}

	> :first-child {
		padding-right: 1em;
	}

	> :last-child {
		padding-left: 1em;
    }
}

.labels {
    label {
        margin: 0 0 10px 0;
        border-right: 1px solid black;
    }
}

.formWrapper {
    * {
        font-size: 12px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0;
    gap: 50px;
    .divider {
        margin: 20px 0 0;
        display: grid;
        grid-template-columns: 1fr 2fr;
        &:after {
            content: '';
            display: block;
            width: 1px;
            height: 45px;
            background:rgba(112, 112, 112, .21);
            position: absolute;
            left: 90px;
        }
        .radioGroup {
            display: grid;
            grid-template-columns: 1fr 1fr;
            > * {
                padding-left: 25px;
                background-color: white;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.53);
        
                &:first-child {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
        
                &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }
    .numberInput {
        border: 1px solid rgba(112, 112, 112, .56);
        width: 100px;
        span {
            background: rgba(112, 112, 112, .3);
            padding: 3px 5px;
            font-size: 15px;
            font-weight: bold;
            width: 25%;
            display: inline-block;
            text-align: center;
            cursor: pointer;
        }
        input {
            height: 24.5px;
            width: 45px;
            width: 50%;
            border: unset;
            text-align: center;
            outline: unset;
            font-weight: bold;
        }
    }
    .box {
        width: 100%;
        max-height: 65px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.50);
        padding: 10px;
        h1 {
            font-size: 14px;
            color: #707070;
            margin: 0 0 10px 0;
        }
        input {
            width: 100%;
            border: unset;
            outline: none;
        }
        ::placeholder {
            color: rgba(112, 112, 112, .2);
        }
    }
}