.modalWrapper {
	background-color: rgba(0, 0, 0, 0.90) !important;
	overflow: hidden;
	header {
		background: transparent;
	}
	img {
		width: auto;
		height: auto;
		max-height: 100%;
	}
}

.transparentBackground {
	background-color: unset !important;
	box-shadow: unset !important;
	overflow: unset !important;
}

.closeIconWrapper {
	right: 30px;
	position: absolute;
	top: 30px;
	cursor: pointer;
}

.imageWrapper {
	width: 100%;
	height: 350px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.arrowIcon {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translate(-25%, -50%);
	&.leftArrow {
		left: -30px;
	}
	&.rightArrow {
		right: -30px;
	}
	i {
		color: #fff;
		font-size: 42px;
	}
}
