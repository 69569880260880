.bets > * {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  .calendarInput {
    border: 1px solid rgba(112, 112, 112, 0.56);
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  label {
    margin-bottom: 0;
  }
}
