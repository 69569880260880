.wrapper {
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.layout {
  display: grid;
  grid-template-columns: 150px 650px;
  font-size: 12px;

  p {
    margin: 0;
  }

  > :first-child {
    padding-right: 1em;
  }

  > :last-child {
    padding: 3.5em 1em 0 1em
  }

  .currentTab {
    display: flex;
    flex-direction: column;
  }
}

.customerInfo {
  color: rgba(0, 0, 0, 0.7);
  padding: 0 0 0 1em !important;
}

.menu {
  border-right: 1px solid rgba(112, 112, 112, 0.2);
  user-select: none;

  > * {
    position: relative;
    cursor: pointer;
    margin: 20px 0;
    font-weight: bold;
    color: #272727;

    &.active:before {
      transform: scaleY(1);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      width: 3px;
      height: 100%;
      background-color: #ebc317;
      transition: transform .3s;
      transform: scaleY(0)
    }
  }
}