.wrapper {
  flex: 1;

  .download {
    text-decoration: none;
    color: #fff;
    background-color: #989898;
    padding: 3px 8px;
    font-weight: bold;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .reject {
    border: none;
    color: #fff;
    background-color: #989898;
    padding: 3px 8px;
    font-weight: bold;
  }

  .fileBar {
    display: flex;
    justify-content: flex-end;
    background-color: #00807f;
    padding: 5px 20px;
  }

  .fileWrapper {
    overflow: hidden;
    position: relative;
    input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
    }
    .btnFile {
      height: 25px;
    }
  }
}
