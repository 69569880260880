.buttons {
  display: grid;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  justify-content: flex-end;
  padding: 20px;

  button {
    border: 0;
    outline: 0;
    padding: 10px 30px;
  }

  .applyButton {
    border-radius: 2px;
    border: 1px solid #c7c7c7;
    font-size: 11px;
    font-weight: bold;
    color: #c7c7c7;
  }

  .okButton {
    border-radius: 2px;
    background-color: #00c06a;
  }

  .cancelButton {
    background-color: transparent;
    font-size: 11px;
    text-decoration: underline;
    color: #6c6c6c;
    padding: 10px;
  }
}