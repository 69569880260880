$sidebar-gray: #707070;

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.wrapper {
  padding: 10px 5px;

  background-color: #eeeeee;
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.35);

  * {
    font-size: 12px;
  }

  > * {
    padding: 10px;
    margin-top: 5px !important;
    background-color: #ffffff;

    &:first-child {
      margin-top: 0;
    }

    > label {
      color: #585858;
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .dates > div {
    display: grid;
    grid-template-columns: 30% auto;
    margin-top: 5px;

    > div {
      display: grid;
      grid-template-columns: 1fr 30px 1fr;
    }

    &.verification > div {
      display: grid;
      grid-row-gap: 5px;
      grid-template-columns: auto;

      > div {
        display: flex;
        justify-content: center;

        &.radioGroup {
          > * {
            padding-left: 25px;
            background-color: white;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.53);

            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }

  .boxedInput {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(112, 112, 112, 0.56);
    outline: 0;
    font-size: 12px;
    padding: 5px;
    color: #585858;
  }

  .searchInput {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgba(112, 112, 112, 0.39);
    outline: 0;
    font-size: 12px;
    padding: 5px 15px 5px 30px;

    &::placeholder {
      color: $sidebar-gray;
      opacity: 0.36;
    }
  }

  .inputIcon {
    position: absolute;
    left: 10px;
    fill: $sidebar-gray;
    opacity: 0.66;
  }
}