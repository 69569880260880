.wrapper {
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
}

.header {
	font-family: Arial, serif;
	font-size: 12px;
	line-height: 1.42;
	color: rgba(0, 0, 0, 0.7);
	padding: 0 10px;
	border-bottom: 3px solid red;
	position: relative;
	z-index: 1;
	.username {
		position: absolute;
		right: 20px;
		font-size: 14px;
	}
	p {
		font-size: 14px;
		margin: 0 15px 0 20px;
		display: inline-block;
	}
	.uppercase {
		text-transform: uppercase;
	}
	.countryInfo {
		display: inline-block;
		img {
			margin: 0 10px 0 0;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.48);
			width: 24px;
			position: relative;
			top: -1px;
		}
		span {
			font-weight: bold;
		}
	}
}

.header strong {
	font-weight: bolder;
}

.customerMoney {
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-bottom: 1px solid #e0e0e0;
	font-size: 12px;
	.borderDivider {
		border-right: 1px solid #707070;
		margin: 10px -5px;
		padding: 4px 5px 0 0;
		height: 25px;
		div {
			display: flex;
			justify-content: space-around;
		}
	}
	.statusCircle {
		width: 20px;
		height: 20px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.38);
		border-radius: 50%;
		&.active {
			background-color: #00c06a;
		}
		&.inactive {
			background-color: #fd3131;
		}
	}
}

.missing {
	color: red !important;
}

.tableWrapper {
	width: calc(100% - 155px);
	table {
		position: relative;
		z-index: -1;
		tbody {
			// background: #f5f5f5;
			background: #dc3545 ;
			td {
				color: rgba(1, 1, 1, .75) !important;
				font-size: 14px;
			}
		}
	}
}