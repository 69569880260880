.wrapper {
	margin: 0 0 30px 0;
	flex: 1;
	.formLayout {
		display: grid;
		grid-template-columns: 0.2fr 0.4fr;
		margin: 0 0 10px;
		&.extended {
			display: grid;
			grid-template-columns: .3fr .5fr .3fr .5fr;
			> div {
				margin: 0 6px;
			}
			label {
				top: 5px;
			}
			.formWrap {
				display: grid;
				grid-template-columns: 0fr .6fr;
				position: relative;
				.datePicker {
					div {
						width: 25px;
						overflow: initial;
						margin: 10px 0;
					}
				}
				div {
					margin: 0;
					label {
						padding: 0 0 0 20px;
					}
				}
				input {
					padding: 0;
				}
			}
		}
	}
}

.pickedTime {
	border-bottom: 1px solid rgba(112, 112, 112, 0.3);
	padding: 3px 0;
}

.missing {
	color: red;
}

.title {
	margin: 0 0 20px;
	font-size: 13px;
	font-weight: bold;
	color: #272727;
	text-transform: uppercase;
}
