.addFormWrapper {
	.deleteBtn {
		position: relative;
		top: 8px;
	}
}

.fixedButtons {
	position: fixed;
	z-index: 100;
	right: 0;
	button {
		display: block;
		left: 0;
		border-radius: 0;
		margin: 0 !important;
		width: 40px;
		transition: .2s;
		&:hover {
			transform: scale(1.1);
		}
	}
}
