$sidebar-gray: #707070;
$box-height: 690px;
$line-dashes: 1px dashed $sidebar-gray;
$button-dimensions: 20px;

.tableSelector {
  padding: 5px;
  margin-top: 50px;
  max-height: $box-height;
  background-color: white;
  overflow: auto;
  font-size: 14px;

  &, ul {
    display: grid;
    grid-row-gap: 5px;
    width: 100%;
  }

  ul {
    margin-left: $button-dimensions + 5px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -($button-dimensions - 5px);
      top: -5px;
      height: calc(100% - 5px);
      border: $line-dashes;
    }

    li:before {
      content: '';
      position: absolute;
      left: -($button-dimensions - 5px);
      width: $button-dimensions - 5px;
      border: $line-dashes;
      height: 0;
    }
  }

  li {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    user-select: none;

    &.selected:after {
      content: '';
      width: 1000%;
      height: 100%;
      top: 0;
      left: -500%;
      position: absolute;
      background-color: rgba(235, 195, 23, 0.25);
    }

    svg {
      margin-right: 5px;
    }

    .openedBtn {
      cursor: pointer;
      font-size: 13px;
      width: $button-dimensions;
      height: $button-dimensions;
      padding: 0;
      outline: 0;
      text-align: center;
      border: 1px solid $sidebar-gray;
      background-color: white;
      margin-right: 5px;
      z-index: 1;
    }
  }
}
