.stake {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;

    label {
      margin: 0;
      font-weight: bold;
      line-height: 1.17;
      text-transform: uppercase;
    }

    .radioGroup {
      display: flex;
      margin-left: auto;
    }
  }

  .totals {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    > * {
      display: grid;
      margin-top: 5px;
      grid-template-columns: 1fr 30% 10px 30%;
      grid-column-gap: 5px;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
        font-size: 12px;
        font-family: Arial, serif;
        color: rgba(0, 0, 15, 0.6);
      }

      input {
        border: 1px solid rgba(0, 0, 15, 0.6);
        color: rgba(0, 0, 15, 0.6);
        font-size: 12px;
      }
    }
  }
}
