.wrapper {
	display: grid;
	grid-template-columns: 0.8fr 1.2fr;
	grid-row-gap: 30px;
	grid-gap: 50px;
	flex: 1;
	.deposit {
        position: relative;
		.formLayout {
			margin: 0 0 10px;
			label {
				position: relative;
				top: 5px;
			}
			display: grid;
			grid-template-columns: .3fr .5fr;
			grid-column-gap: 0;
		}
		.moveToBottom {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        .activationInput {
            display: grid;
            margin: 20px 0 20px;
            grid-template-columns: 0.7fr 1.3fr;
            div {
                text-align: right;
                input {
                    margin: 10px 0 0;
                }
            }
        }
	}
	.exclusion {
		.selfExclusionWrapper {
			width: 100%;
			border: 1px solid #707070;
			max-height: 400px;
			height: 100%;
			.section {
				display: flex;
				justify-content: space-between;
				width: 100%;
				height: auto;
				border-bottom: 1px solid rgba(112, 112, 112, .21);
				padding: 28px 10px;
				&:last-child {
					border-bottom: unset;
				}
				.calendar{
					position: absolute;
					right: 250px;
				}
				p {
					color: #b9b9b9;
					padding: 10px 0 0;
				}
			}
		}
		.section:nth-child(2){
			display: grid;
			grid-template-columns: 1fr 1.2fr 0.6fr;
			grid-gap: 5px;
		}
		.selfExclusionWrapper::-webkit-scrollbar {
			width: 5px;
		}
		
		.selfExclusionWrapper::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			border-radius: 50px;
		}
		 
		.selfExclusionWrapper::-webkit-scrollbar-thumb {
			background-color: #ebc317;
			outline: 1px solid #ebebeb;
			border-radius: 50px;
		}
	}
}

.title {
	margin: 0 0 20px;
	font-size: 13px;
	font-weight: bold;
	color: #272727;
	text-transform: uppercase;
}

.input {
	border: unset;
	outline: unset;
	border-bottom: 1px solid rgba(112, 112, 112, .61);
}