.wrapper {
  background-color: #d6d6d6;
  font-size: 12px;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;

    > div {
      display: flex;
      align-items: center;
    }

    .betting {
      display: flex;
      align-items: center;
      background-color: #ff8000;

      .text {
        color: #ffffff;
        font-weight: bold;
      }

      .percent {
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.69);
        margin: 5px;
      }
    }

    .name {
      padding-left: 5px;
      font-weight: bold;
      color: rgba(1, 1, 1, 0.75);
    }

    .feedProvider {
      font-weight: bold;
      color: rgba(1, 1, 1, 0.75);
    }

    .lastUser {
      background-color: rgba(190, 192, 255, 0.5);
      margin-left: 10px;
      padding: 0 5px;
    }
  }

  .filters {
    display: flex;

    * {
      color: rgba(1, 1, 1, 0.5);
      font-size: 10px;
    }

    > * {
      display: flex;
      align-items: center;
      margin-left: 10px;

      * {
        margin-left: 10px !important;
      }
    }

    > *:not(:first-child) {
      border-left: solid 1px #dddddd;
    }

    i {
      width: 20px !important;
      height: 15px !important;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .controls {
    display: flex;
    align-items: center;

    .btnSuccess {
      font-size: 10px;
      font-weight: bold;
      background-color: #00c06a;
      color: white;
    }
  }
}
