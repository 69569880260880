.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.Range {
  width: 7 * 30px;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 2;

  .DayPicker {
    &-wrapper {
      outline: 0;
      border-radius: 10px;
      box-shadow: 2px 2px 10px 0 #707070;
      padding: 0;
    }

    &-Month {
      width: 100%;
      margin: 0;
      background-color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &-NavBar {
      display: flex;
      color: #707070;
      font-weight: bold;
      font-size: 13px;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #ebc317;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .calendar-btn {
        width: 30px;
        height: 30px;
        color: #707070;
        border-radius: 50%;
        background-color: transparent;
        border: 2px solid #707070;
        cursor: pointer;
        outline: 0;

        &:hover {
          background-color: #707070;
          color: #ebc317;
        }
      }
    }

    &-Caption {
      display: none;
    }

    &-Day {
      outline: 0;
      position: relative;
      font-size: 12px;

      &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        color: #909090 !important;
        font-weight: bold;
      }

      &--today {
        color: #ebc317 !important;
      }

      &--start, &--end {
        background-color: #ebc317 !important;
        color: #505050 !important;
        font-weight: bold;
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .5);
      }

      &--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: transparent !important;
      }

      &--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):before {
        content: '';
        width: 100%;
        height: 50%;
        position: absolute;
        left: 0;
        top: 25%;
        background-color: lighten(#ebc317, 20%);
        opacity: .5;
      }
    }
  }
}
