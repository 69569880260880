.wrapper{
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    margin: 0 25px 15px;
    font-size: 13px;
    font-weight: bold;
    color: #272727;
    text-transform: uppercase;
  }
  
  .statusCircle {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.38);
    border-radius: 50%;
    margin: auto;
  }
  
  .active{
    @extend .statusCircle;
    background-color: #00c06a;
  }
  
  .inactive{
    @extend .statusCircle;
    background-color: #fd3131;
  }
  
  .autoWithdraw{
    display: grid;
    grid-template-columns: 1.3fr .7fr;
    padding-top: 20px;
  
    .formLayout {
      margin: 0 0 10px;
      label {
        position: relative;
        top: 5px;
      }
      display: grid;
      grid-template-columns: .3fr .5fr;
      grid-column-gap: 0;
    }
  }
}





