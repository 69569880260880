.additionalWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  flex: 1;
  .formWrapper {
    .divider {
      display: grid;
      grid-template-columns: 0.4fr 1fr;
      margin: 0 0 15px;
      align-items: center;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 40px;
        background: rgba(112, 112, 112, 0.3);
        position: absolute;
        left: 120px;
      }
      label {
        margin: 0 20px 0 0;
        width: 110px;
        position: relative;
      }
    }
    .betFactor {
      border: 1px solid rgba(112, 112, 112, 0.56);
      width: 100px;
      span {
        background: rgba(112, 112, 112, 0.3);
        padding: 3px 5px;
        font-size: 15px;
        font-weight: bold;
        width: 25%;
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }
      input {
        height: 24.5px;
        width: 45px;
        width: 50%;
        border: unset;
        text-align: center;
        outline: unset;
        font-weight: bold;
      }
    }
    .reoffer {
      p {
        margin: 22px 0;
      }
    }
  }
}

.missing {
  color: red;
  &::placeholder {
    color: red;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.infoMessage {
  margin: 10px 0 50px;
  .infoBox {
    margin: 0 0 10px;
    padding: 10px;
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.31);
    &.yellowBox {
      background: #ebc317;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.77);
      .title {
        font-size: 15px;
      }
      p {
        color: #707070;
        margin: 0 0 10px;
      }
      .author {
        color: #272727;
      }
    }
    .removeBtn {
      position: absolute;
      right: 5px;
      bottom: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
    input {
      border: unset;
      display: block;
      margin: 0 0 5px 0;
      padding: 5px 0;
      background: transparent;
      outline: unset;
      color: #9c9c9c;
      &:first-child {
        font-size: 14px;
      }
      &:last-child {
        font-size: 12px;
        width: 100%;
      }
    }
    .author {
      color: #9c9c9c;
      font-style: italic;
    }
    button {
      position: absolute;
      right: 5px;
      bottom: 10px;
    }
  }
}

.tournamentTable {
  border: 1px solid rgba(112, 112, 112, 0.15);

  .addTournamentButton {
    background-color: transparent;
    border: 0;
    color: #2ecc71;
    font-weight: bold;
    margin-left: 20px;
  }
}

.inputWithIcon {
  width: 80px;
}

.cashoutSwitch {
  padding: 20px;
  text-align: center;
}
