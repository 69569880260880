.menu {
	position: fixed;
	background-color: #eeeeee;
	box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.35);
	width: 300px;
	padding: 10px;
	left: 15px;
	min-height: 100vh;

	.divider {
		margin: 0 0 10px;
	}
	
	> * {
		background-color: white;
		padding: 10px;

		> label {
			margin: 0;
			font-weight: bold;
			line-height: 1.17;
			text-transform: uppercase;
		}
	}

	label {
		font-family: Arial, serif;
		font-size: 12px;
		color: #585858;
	}

	.account {
		padding: 0 10px 5px;
		background: #fff;
		label {
			 &:first-child {
				margin: 0 0 10px 0;
			 }
		}
		.radioGroup {
			> * {
				text-align: center;
				padding-left: 25px;
				background-color: white;
				box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.53);
				display: inline-block;
				text-align: center;
				label {
					min-width: 50px;
					text-align: right;
				}
				&:first-child {
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
				}
				&:last-child {
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
			}
		}
	}

	.transaction {
		background: #fff;
		padding: 0 10px 5px;
		label {
			margin: 0 0 10px 0;
		}
		.labelInput {
			display: grid;
			padding: 0;
			grid-template-columns: .5fr 1fr;
		}
	}

	.inlineInputsWrapper {
		.labelInput {
			background: #fff;
			display: grid;
			grid-template-columns: .5fr 1fr;
			label {
				margin: 0;
				font-weight: bold;
				line-height: 1.17;
				text-transform: uppercase;
				position: relative;
				top: 3px;
			}
		}
	}

	.txTime {
		padding: 0 10px 10px;
		background: #fff;
		label {
			&:first-child {
				margin: 0 0 10px 0;
			}
		}
		.labelInput {
			display: grid;
			grid-template-columns: .5fr 1fr;
			padding: 0;
			&:last-child {
				margin: 20px 0 0;
			}
		}
		.dateBox {
			border: 1px solid rgba(112, 112, 112, 0.2);
			position: relative;
			> div {
				&:first-child {
					overflow: initial;
					position: relative;
					top: 12px;
					left: 5px;
				}
			}
			input {
				width: 120px;
				margin: 0 5px 0 0;
				border: unset;
			}
			svg {
				position: absolute;
				right: 5px;
				top: 0;
			}
			.pickedTime {
				display: inline-block;
				position: relative;
				top: 0;
				left: 40px;
				padding: 0;
				margin: 0;
				font-size: 12px;
			}
		}
	}

	.invoice {
		padding: 0 10px 10px;
		label {
			&:first-child {
				margin: 0 0 10px 0;
			}
		}
		.checkboxWrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
	}

	.backButton {
		width: 30px;
		height: 30px;
		border-radius: 5px;
		border: solid 1px #c1c1c1;
		background-color: #ffffff;
		display: grid;
		place-items: center;
		padding: 0;
		float: right;
		color: rgba(112, 112, 112, 0.5);
		outline: 0;
		cursor: pointer;
	}
}

.rc-time-picker-panel {
	position: fixed;
}

.timePickerWrapper {
	> span {
		position: relative;
		left: 35px;
		font-family: Arial;
	}
	input { 
		outline: none;
	}
}

.missing {
	color: red !important;
}