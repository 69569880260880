.searchWrapper {
	padding: 10px;
	label {
		display: unset;
	}
}

.results {
	display: grid;
	grid-template-rows: 300px auto;

	.table {
		overflow: auto;
		background-color: #f5f5f5;
		tr {
			td {
				line-height: 2.64;
				button {
					height: unset;
					font-size: 12px;
					text-transform: capitalize;
					padding: 5px;
					margin: 0 auto;
				}
			}
		}
	}
	.table::-webkit-scrollbar {
		width: 5px;
	}

	.table::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 50px;
	}

	.table::-webkit-scrollbar-thumb {
		background-color: #ebc317;
		outline: 1px solid #ebebeb;
		border-radius: 50px;
	}
}
