header {
  display: flex !important;
  .username{
    position: absolute;
    right: 30px;
  }
  .fullName{
    padding: 0 10px;
  }
  .countryInfo {
    display: inline-block;
    img {
      margin: 0 10px 0 0;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.48);
      width: 20px;
      position: relative;
      top: -1px;
    }
  }
}