.info {
  display: grid;

  > * {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 10px;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: rgba(1, 1, 1, 0.75);

    > :first-child {
      border-right: 1px solid #707070;
      padding: 0.5rem 5px;
      font-weight: normal;
    }
  }
}

.header {
  display: flex;
  font-family: Arial, serif;
  font-size: 12px;
  color: rgba(1, 1, 1, 0.75)
}

.input {
  border: 0;
}

.whiteTable {
  width: 100%;
  margin-top: 2rem;
  border: 1px solid rgba(112, 112, 112, 0.28);

  th, td {
    font-family: Arial, serif;
    font-size: 11px;
    color: #272727;
    font-weight: normal;

    &:not(:first-child) {
      text-align: right;
    }
  }

  td {
    font-size: 12px;
    font-weight: bold;
    color: rgba(1, 1, 1, 0.75)
  }
}

.numberInput {
  display: flex;

  input {
    border: 1px solid #707070;
    padding: 5px 10px;
    font-size: 12px;
    height: 100%;
    border-left: 0;
    border-right: 0;
    outline: 0;
    width: 50px;
    text-align: center;
  }

  button {
    outline: 0;
    width: 30px;
    color: #707070;
    cursor: pointer;
    border: 1px solid #707070;
    background-color: rgba(112, 112, 112, 0.3);

    &:first-child {
      border-right: 0;
    }

    &:last-child {
      border-left: 0;
    }
  }
}

.buttons {
  display: grid;
  margin-left: auto;
  margin-top: 1rem;
  width: 50%;
  grid-template-columns: 1fr 1fr;

  .btnSuccess {
    background-color: #00c06a;
    color: white;
  }

  .btnSecondary {
    background-color: #c7c7c7;
    color: white;
  }
}