@import "../../resources/styles/scss/variables";

.pulseAnim > * {
  box-shadow: 0 0 0 0 $main;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(254,183,25, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(254,183,25, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(254,183,25, 0);
  }
}