.checkbox {
  max-height: 300px;
  overflow: auto;

  .list {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .selectAll {
    user-select: none;
    color: rgba(88, 88, 88, 0.41);
    font-weight: bold;
    font-size: 10px;
    text-decoration: underline;
    margin-bottom: 0;
    cursor: pointer;
    float: right;
  }
}
