.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.menu {
  padding: 12.5px;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.31);

  li {
    font-family: "Segoe UI", serif;
    font-size: 12px;
    color: #707070;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }
}