
.wrapper {
    width: 70% !important;
    overflow-x: hidden !important;
}

.headerFlagIcon {
    display: inline-block;
    i {
        position: relative;
        left: 10px;
        font-size: 19px;
    }
}