.searchFilter{
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  align-items: center;
  background-color: #eee !important;

  .search{
    position: relative;
  }

  .search input {
    width: 100%;
    border-radius: 13px;
    background-color: #fff;
    border: none;
    outline: none;
    padding-left: 22px;
  }

  .search svg{
    position: absolute;
    left: 5px;
  }
}


