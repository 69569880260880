.header {
	display: grid;
	grid-template-columns: 200px 480px;
	padding: 5px 10px;
	border-bottom: 1px solid #707070;
	p {
		margin: 0;
	}
	div {
		position: relative;
	}
	.name,
	.id {
		position: absolute;
	}
	.name {
		left: 5px;
	}
	.id {
		right: 35px;
	}
}

.wrapper {
	display: grid;
	grid-template-columns: 200px 500px;
	.divider {
		border-right: 1px solid #707070;
		padding: 10px;
	}
	table {
		width: 100%;
		background: #f5f5f5;
		tbody {
			td {
				color: #575757 !important;
			}
		}
		.active,
		.inactive {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
		}
		.active {
			background: green;
		}
	}
	h1 {
		margin: 20px 0 10px;
		font-size: 13px;
		text-transform: uppercase;
	}
	.dateWrapper {
		display: grid;
		grid-template-columns: .2fr 1fr;
		font-size: 12px;
		p {
			margin: 0;
			padding: 5px 0;
		}
		> div > div {
			width: unset;
			display: inline-block;
			margin: 5px 0 0 5px;
		}
		.dateBox {
			border: 1px solid rgba(112, 112, 112, 0.2);
			position: relative;
			input {
				width: 120px;
				margin: 0 5px 0 0;
				border: unset;
			}
			svg {
				position: absolute;
				right: 5px;
				top: 0;
			}
			.pickedTime {
				display: inline-block;
				position: relative;
				top: -4px;
				left: 6px;
				padding: 0;
			}
		}
	}
	.inputWrapper {
		display: grid;
		grid-template-columns: .5fr 1fr;
		font-size: 12px;
		margin: 30px 0;
		p {
			margin: 0;
		}
	}
}

.tableWrapper {
	.operatorTable {
		tbody {
			td {
				&:first-child {
					text-align: center !important;
					width: 130px;
				}
				&:nth-child(2) {
					text-align: left !important;
					width: 400px;
				}
			}
		}
	}
	.dataTableWrapper {
		box-shadow: 0 -3px 9px -2px rgba(0, 0, 0, 0.31);
		thead {
			th {
				background: #00807f;
				color: #fff;
			}
		}
		tbody {
			td {
				&:first-child {
					background: rgba(0, 128, 127, .29);
				}
				background: rgba(0, 128, 127, .15);
			}
		}
	}
}

.pageCounter {
	margin: 5px 10px;
	font-size: 12px;
}

.missing {
	color: red;
}
