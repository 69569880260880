.inputsFromTo{
  display: grid;
  grid-template-columns: 0.7fr 0.7fr 0.1fr 0.7fr;
  place-items: center;
  grid-gap: 5px;
  .inputs {
    border: 1px solid rgba(112, 112, 112, 0.56);
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}