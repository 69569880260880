$border: 1px solid rgba(112, 112, 112, 0.15); // #707070 with 0.15 opacity

.table {
  position: relative;
  width: 100%;
  overflow: auto;

  * {
    font-family: Arial, serif;
    font-size: 11px;
  }

  .col {
    position: relative;
    background-color: #ebc317;
    font-weight: normal;
    line-height: 1.64;
    color: rgba(14, 13, 13, 0.7);
    text-align: center;
    &:not(:first-child) {
      border-left: $border;
    }

    &.inactiveCol {
      background-color: rgba(167, 167, 167, 0.81);
    }

    &.buttonCol {
      background-color: #00c06a;
      color: #ffffff;
      cursor: pointer;
      border-left: 2px solid white;
    }
  }

  .row {
    border-bottom: $border;

    td {
      &:not(:first-child) {
        border-left: $border;
      }
      line-height: 1.64;
      font-weight: normal;
      color: black;
      text-align: center;
    }
  }
}

.table::-webkit-scrollbar {
  width: 5px;
}

.table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
}
 
.table::-webkit-scrollbar-thumb {
  background-color: #ebc317;
  outline: 1px solid #ebebeb;
  border-radius: 50px;
}

