@import "../../resources/styles/scss/variables";

.wrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageButtons button {
  padding: 10px 20px;
  background-color: white;
  border-top: 2px solid $main;
  border-bottom: 2px solid $main;
  border-left: 0;
  border-right: 0;
  font-size: 14px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: darken(white, 5%);
  }

  &:disabled {
    background-color: darken(white, 10%);
  }

  &:first-child {
    border-left: 2px solid $main;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-right: 2px solid $main;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}