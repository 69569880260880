.backgroundLayerPopUp {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 3;
  overflow-y: auto;
}

.closeModal {
  color: #c0c0c0;
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: unset;
  z-index: 11;
  i{
    cursor: pointer;
  }
}