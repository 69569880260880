@import '../../../../resources/styles/scss/variables';

.customerDetailsWrapper {
	overflow: auto;
	background: $white;
	position: relative;
	box-shadow: -3px -1px 6px 0 rgba(0, 0, 0, 0.16);
	p,
	b {
		font-size: 12px;
	}
	.flexSpaceBetween {
		div {
			display: flex;
			justify-content: space-between;
		}
	}
	.customerHeader {
		padding: 10px 20px;
		border-bottom: 4px solid #fd3131;
		p {
			display: inline-block;
			font-family: Arial, Helvetica, sans-serif;
			font-weight: bold;
			font-size: 14px;
			margin: 0;
		}
		.customerName {
			margin: 0 10px 0 0;
		}
	}
	.customerMoney {
		display: flex;
		flex-direction: row;
		justify-content: center;
		border-bottom: 1px solid #e0e0e0;
		.borderDivider {
			border-right: 1px solid #707070;
			margin: 10px -5px;
			padding: 4px 5px 0 0;
			height: 25px;
			div {
				display: flex;
				justify-content: space-around;
			}
		}
		b {
			font-size: 12px;
		}
		.statusCircle {
			width: 20px;
			height: 20px;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.38);
            border-radius: 50%;
            &.active {
                background-color: #00c06a;
            }
            &.inactive {
                background-color: #fd3131;
            }
		}
	}
	.basicInfo {
		padding: 15px 30px 0 0;
		margin: 15px 0;
		.extraInfoDiv {
			width: 100%;
			min-height: 70px;
			box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
			background-color: #ffffff;
			padding: 10px;
			position: relative;
			margin: 0 0 15px 0;
			.title {
				font-size: 16px;
			}
			&.extraInfoYellow {
				background: #ebc317;
			}
			p,
			span {
				color: #272727;
				margin: 0;
			}
			.authorName {
				position: absolute;
				bottom: 5px;
				right: 15px;
				font-style: italic;
				font-size: 12px;
			}
		}
	}
	.calculationsWrapper {
		padding: 0 0 10px 0;
		.greenNumber {
			color: #00c06a;
			font-weight: bold;
		}
		.header {
			padding: 5px;
			background-color: #f0d45b;
			p {
				font-weight: bold;
				margin: 0;
			}
		}
		.body {
			background-color: rgba(240, 212, 91, 0.2);
			padding: 5px 0 20px;
			min-height: 210px;
			&.bodyPadding {
				padding: 5px 20px;
			}
			p {
				margin: 2px;
			}
		}
		.sum {
			&.sumPadding {
				padding: 0 20px 0;
			}
			background-color: rgba(240, 212, 91, 0.5);
			p {
				margin: 3px;
			}
		}
	}
}

.borderRight {
	border-right: 1px solid #707070;
}

.countryIcon {
	height: 15px !important;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.48);
	margin: 0 10px 0 0;
}
