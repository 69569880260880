.wrapper {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  > div {
    div::-webkit-scrollbar {
      width: 5px;
    }
    div::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 50px;
    }
    div::-webkit-scrollbar-thumb {
      background-color: #ebc317;
      outline: 1px solid #ebebeb;
      border-radius: 50px;
    }
  }
  .sidebar {
    transition: 1s margin-left;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}

.header * {
  font-family: Arial, serif;
  font-size: 12px;
  line-height: 1.42;
  color: rgba(0, 0, 0, 0.7);
}

.header strong {
  font-weight: bolder;
}
