.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  flex: 1;
  button {
    margin: 0;
  }

  h1 {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: bold;
    color: #272727;
    text-transform: uppercase;
  }

  .info {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 50px;

    .fullName{
      grid-template-columns: 1fr 2fr 3fr;
    }

    label {
      margin: 0;
      font-size: 12px;
    }

    input {
      font-weight: bold;
    }

    > div > div {
      display: grid;
      grid-row-gap: 10px;

      &.idInfo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 10px;

        > div {
          display: grid;
          align-items: center;
          grid-auto-flow: column;
          grid-template-columns: 0.6fr 1fr;
          grid-column-gap: 10px;
        }
      }

      &.channels {
        display: grid;
        grid-row-gap: 10px;
      }

      &:not(.idInfo):not(.channels) > div {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 7fr;
        grid-column-gap: 7px;

        &.accountInfo{
          grid-template-columns: 0.4fr 1fr;
        }

        &.lastLine {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;
        }

        div {
          display: grid;
          align-items: center;
          grid-auto-flow: column;
          grid-column-gap: 10px;
        }
      }
    }
  }

  .saveButton {
    border: 0;
    outline: 0;
    padding: 10px 30px;
    border-radius: 2px;
    background-color: #00c06a;
  }

  .address p {
    color: rgba(39, 39, 39, 0.27);
    font-size: 11px;
  }
}