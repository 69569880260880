.wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;

  .main {
    padding: 20px;

    .row > td {
      font-size: 14px;
    }
  }
}