.wrapper {
  display: grid;
  height: 100%;
  grid-template-columns: 0.2fr 1fr;

  .results {
    display: grid;
    grid-template-rows: 300px auto;

    .table {
      overflow: auto;
      background-color: #f5f5f5;
    }
    .table::-webkit-scrollbar {
      width: 5px;
    }
    
    .table::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 50px;
    }
     
    .table::-webkit-scrollbar-thumb {
      background-color: #ebc317;
      outline: 1px solid #ebebeb;
      border-radius: 50px;
    }
  }

  .highlightedRow {
    background-color: rgba(235, 195, 23, 0.6);
  }

  .countryIcon {
    height: 20px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.48);
  }
}