.wrapper {
  position: absolute;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 1px;
  background-color: #707070;
  box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.3);

  .box {
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    color: #272727;
    padding: 5px 10px;
  }

  .active {
    @extend .box;
    background-color: white;
  }

  .inactive {
    @extend .box;
    background-color: #f6f5f5;
  }
}