.radio {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;

  .radioGroup {
    display: flex;
    align-items: center;
    margin-left: auto;

    .radioInput {
      margin: 0 10px 0 20px;

      input:checked ~ label:before {
        background-color: #ebc317;
      }

      input {
        display: none;
      }

      label {
        min-width: 0;
        padding: 0;
        font-size: 12px;
        line-height: 2.08;
        font-weight: normal;
        font-family: Arial, serif;
        color: rgba(39, 39, 39, 0.6);

        &:hover:before {
          background-color: lighten(#ebc317, 30%);
        }

        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          padding: 3px;
          left: -20px;
          top: 50%;
          transform: translate(0, -50%);
          width: 6px;
          height: 6px;
          border: 1px solid #707070;
          border-radius: 50%;
          background-clip: content-box;
          box-sizing: content-box;
          background-color: white;
        }
      }
    }
  }

}

