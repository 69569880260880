.radioButtons {
  display: grid;
  align-items: center;
  grid-gap: 10px 0;

  .radioGroup {
    display: flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    & > div {
      width: 100%;
      border: 3px solid #e3e3e3;
      padding: 0 10px 0 25px;
     

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;
      }
  
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
      }
    }
  }
}



