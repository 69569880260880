.settlement{
  width: calc(100% - 300px);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  font-size: 12px;
  .settData{
    background-color: #00807f;
    color: #fff;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px;
    .row{
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      border-right: 1px groove #fff;
    }
    .row:last-child{
      border-right: none;
    }
    .row > div:last-child {
      color:red;
    }
  }
  .settData:nth-last-child(2){
      background-color: #fff !important;
      color: #000 !important;
  }
}
.extAdditional {
  width: 100%;
  height:200px;
  background-color: #fff;
  padding: 10px;
   div {
    background-color: #4bbbba;
    color: red;
    width: 600px;
    height: 80px;
    box-shadow: -3px -1px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 5px 10px;
  }
}