.config {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.marketTypeConfig {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}
