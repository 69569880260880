.header {
	display: grid;
	grid-template-columns: 200px 500px;
	div {
		position: relative;
	}
	.name,
	.id {
		position: absolute;
	}
	.name {
		left: 5px;
	}
	.id {
		right: 35px;
	}
}

.wrapper {
	display: grid;
	grid-template-columns: .5fr 1fr;
	.divider {
		border-right: 1px solid #707070;
		padding: 0 25px 0 0;
	}
	table {
		width: 100%;
		background: #f5f5f5;
		tbody {
			td {
				color: #575757 !important;
			}
		}
		.active,
		.inactive {
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			margin: 0 auto;
		}
		.inactive {
			background: red;
			cursor: pointer;
		}
		.active {
			background: #00c06a;
		}
	}
	h1 {
		margin: 20px 0 10px;
		font-size: 13px;
		text-transform: uppercase;
	}
	.dateWrapper {
		display: grid;
		grid-template-columns: .2fr 1fr;
		font-size: 12px;
		p {
			margin: 0;
			padding: 5px 0;
		}
		> div > div {
			width: unset;
			display: inline-block;
			margin: 5px 0 0 5px;
		}
		.dateBox {
			border: 1px solid rgba(112, 112, 112, 0.2);
			position: relative;
			input {
				width: 120px;
				margin: 0 5px 0 0;
				border: unset;
			}
			svg {
				position: absolute;
				right: 5px;
				top: 0;
			}
			.pickedTime {
				display: inline-block;
				position: relative;
				top: -4px;
				left: 6px;
				padding: 0;
			}
		}
	}
	.checkboxWrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		font-size: 12px;
		> div {
			display: grid;
			grid-template-columns: .2fr 1fr;
		}
		p {
			margin: 0;
			color: rgba(0, 0, 0, 0.75);
			display: inline-block;
		}
	}
}

.pageCounter {
	margin: 5px 10px;
	font-size: 12px;
}

.searchInput {
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 15px;
	border: 1px solid rgba(112, 112, 112, 0.39);
	outline: 0;
	font-size: 12px;
	padding: 5px 15px 5px 30px;

	&::placeholder {
		color: #707070;
		opacity: 0.36;
	}
}

.inputIcon {
	position: absolute;
	left: 10px;
	fill: #707070;
	opacity: 0.66;
}

.missing {
	color: red !important;
}

.pagination {
	list-style: none;
	text-align: center;
	li {
		display: inline-block;
		cursor: pointer;
		font-size: 12px;
		padding: 3px 5px;
		color: lightgray;
		&.active {
			color: #000;
		}
	}
}

.paginationNav {
	display: inline-block;
	font-size: 11px;
}

.disabled {
	span {
		color: #cbcbcb;
	}
}
